import _ from 'lodash';
import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import i18n from '../../i18next';
import {
    Col,
    NavLink,
    Modal
} from "reactstrap";
import { isIOS, isMobile, isMobileOnly } from 'mobile-device-detect';

import { updateCSSVariables } from '../../utils/ThemesSelector';
import {
    getRestaurantAvailableMenus,
    getSelectedProduct,
    editProduct, // New Wizard edit
    clearMessageAlert,
    selectRestaurantMenu,
    openStartOrderScreen,
    clearShoppingCartState,
    saveBrandIdToStore,
    loadCustomerSavedRestaurants,
    getEstimateOrderTime,
    getMissingPreviewData,
    getOldestActiveLoyalty,
    addProductToCart,
    getServiceMessages,
    clearServiceMessages,
    saveFirstStamp,
    changeAuthipayBackBtnFlag,
    changeSignInPressed,
    updateProfileBackBtnPath,
    togglePromotionsModal,
    toggleShoppingCartModal,
    getComoMemberDetails,
    clearCartAndPromoModals,
    voidPurchase,
    resetPromoCodeField,
    resetOldDiscountFlag,
    cancelComoPayment,
    toggleSearchProductFocus
} from "../../actions/index";
import ShoppingCart from './shoppingCart/ShoppingCart';

import PhoneNumberScreen from '../../components/login/PhoneNumberScreen';
import LoginTypesScreen from '../../components/login/LoginTypesScreen';

import SmsConfirmationScreen from '../../components/login/SmsConfirmationScreen';
import UserCredentialsScreen from '../../components/login/UserCredentialsScreen';
import StickyNavigationBar from '../../pwa-app/components/order/menu/StickyNavigationBar';

import {
    getLoggedInUserFirstName,
    renderFirstCategoryHiddenId,
    checkIfMenuIsAvailable,
    checkIfCategoryIsAvailable,
    checkIfProductIsAvailable
} from "../../utils/CommonUtils";
import { pushDataToGoogleTagManager, getProductCategory } from '../../utils/AnalyticsUtils';
import { sendMessageToParentSite } from "../../utils/CrossOriginUtils";
import { calculateProductPrice } from '../../utils/ProductWizardUtils';

import ProductsScreenPwa from "../../pwa-app/components/order/menu/ProductsScreenPwa";
import UserIconSVG from '../../pwa-app/components/common/svgComponents/UserIconSVG';
import MenuuLogoSVG from '../../pwa-app/components/common/svgComponents/MenuuLogoSVG';
// NEW IMPORTS FOR WIZARD REFACTORING
import CommonModal from '../common/CommonModal';
import ProductWizard from './product/ProductWizard';
import MenuScreenAlerts from './menu/MenuScreenAlerts';
import MenuLoaderContainer from './menu/MenuLoaderContainer';
import ProductsScreen from './menu/ProductsScreen';
import CartItemsButton from './menu/CartItemsButton';
import PromotionsScreen from './promotions/PromotionsScreen';
import ShoppingCartScreen from './shoppingCart/ShoppingCartScreen';
import { ARABIC } from '../../utils/Constants';
import { totalItemsCount } from '../../utils/ShoppingCartUtils';


class MenuScreen extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.selectProduct = this.selectProduct.bind(this);
        this.iosTouchEvents = this.iosTouchEvents.bind(this);
        this.componentScrollEvents = this.componentScrollEvents.bind(this);

        let integrationDetected = false;
        if (new URLSearchParams(window.location.search).get('brandId')) {
            integrationDetected = true;
        }
        this.state = {
            color: '',
            isOpen: false,
            currency: '',
            selectedMenuId: -1,
            selectedCategories: [],
            selectedProductId: -1,
            stateAlreadyCopiedFromProps: false,
            itemOutOfCart: true,
            loadScrollspyElements: false,
            iOSDetected: false,
            lastIndexOfCurrentElement: 0,
            isLoadedFromIntegration: integrationDetected,
            fullscreenOffset: 200,
            onResizeChange: false,
            isProductWizardOpen: false,
            matchingProducts: [],
            hiddenStickyMenu: false,
            isCartModalOpen: false
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.reloadStateToRerender = this.reloadStateToRerender.bind(this);
        this.throttledFunction = _.throttle(this.reloadStateToRerender, 100);
        this.toggleProductWizard = this.toggleProductWizard.bind(this);
        this.sendDataToParent = this.sendDataToParent.bind(this);
        // this.autoAddToCart = this.autoAddToCart.bind(this);
    }

    componentDidMount() {
        // Close shopping cart and promotion modal after refresh or restart order
        this.props.clearCartAndPromoModals();

        // Clear como promotions when refresh from checkout screen
        const isComo = this.props.promotionPartners.promotionPartner && this.props.promotionPartners.promotionPartner.id === 2;
        const isLoggedInPromotionPartner = this.props.promotionPartners.isUserLoggedInPartner;
        
        if (isComo && isLoggedInPromotionPartner) {
            const onSuccess = () => {
                this.props.voidPurchase(() => this.props.resetOldDiscountFlag());
                this.props.resetPromoCodeField();
            };

            this.props.cancelComoPayment(onSuccess);
        } else if (this.props.promotionPartners.promoPartnerDiscountTotal > 0 && this.props.promotionPartners.promotionPartner.id === 2) {
            this.props.voidPurchase(() => this.props.resetOldDiscountFlag());
            this.props.resetPromoCodeField();
        }

        // Get Como Member Details
        if (isComo) {
            this.props.getComoMemberDetails();
        }

        // TIME STAMP
        if (!this.props.pwaAppRunning) {
            let timePeriod = 60 * 60 * 3 * 1000;
            let currentMoment = Date.now();
            let sum = parseInt(this.props.firstStamp) + timePeriod;

            if (sum <= currentMoment) {
                this.props.clearShoppingCartState();
                this.props.saveFirstStamp(currentMoment);
            }
        }

        this.applyTheme();
        updateCSSVariables(this.props.customerThemes.selectedTheme.colorsCss)

        setTimeout(() => {
            this.setState({ loadScrollspyElements: true });
        }, 100);


        // Handle menu sticky on ios devices
        const userAgent = window.navigator.userAgent;
        let userOnMobileIos = userAgent.match(/iPhone; CPU iPhone OS/) || userAgent.match(/CPU iPhone/) || userAgent.match(/iPad;/);

        this.setState({ ...this.state, iOSDetected: userOnMobileIos });

        if (userOnMobileIos && this.state.isLoadedFromIntegration) {
            document.addEventListener('touchend', this.iosTouchEvents, false);
        } else {
            document.addEventListener('scroll', this.componentScrollEvents, false);
        }
        window.addEventListener('orientationchange', this.componentScrollEvents, false);
        window.addEventListener('resize', this.throttledFunction);

        const { customer } = this.props;
        // Gets an active loyalty code whenever user is logged in
        const restaurantID = this.props.menuPreviewMode ? new URLSearchParams(window.location.search).get('restaurantId') : this.props.selectedRestaurant.restaurantId;

        const appliedPromotions = this.props.shoppingCart ? this.props.shoppingCart.appliedPromotions : null;
        let isAppliedPromotionWithPromoCode = false;
        if(appliedPromotions && appliedPromotions.length) {
            appliedPromotions.map((promotion, index) => {
                if(promotion.promoCode) {
                    isAppliedPromotionWithPromoCode = true;
                }
            })
        }

        if (customer.token && !this.props.storeLocalStorage.codeManuallySet && customer.autoApplyLoyalty && !isAppliedPromotionWithPromoCode) {
            this.props.getOldestActiveLoyalty(restaurantID);
        }

        this.props.clearServiceMessages();
        this.props.getServiceMessages(restaurantID, this.props.orderTypes.selectedOrderType.value);

        if (document.getElementById('generalNote')) {
            document.getElementById('generalNote').innerHTML = this.props.menuDescription.generalNote;
        }

        if (this.props.paymentProvider.providerType && this.props.paymentProvider.providerType.id === 4) {
            //set menu screen flag to local storage when authipay is configured 
            this.props.changeAuthipayBackBtnFlag(true);
        }



        // this function prevent to go back from menu screen to the checkout
        // const unblock = this.props.history.block((location, action) => {
        //     if (action === 'POP' && location.pathname === '/checkout') {
        //         return false;
        //     }
        //     return unblock();
        // })

        this.props.updateProfileBackBtnPath('/menu-screen');
    }

    reloadStateToRerender() {
        this.setState({
            ...this.state,
            onResizeChange: !this.state.onResizeChange
        });
    }

    componentWillUnmount() {
        document.removeEventListener('touchend', this.iosTouchEvents, false);
        document.removeEventListener('scroll', this.componentScrollEvents, false);
        window.removeEventListener('orientationchange', this.componentScrollEvents, false);
        sendMessageToParentSite('else');
        window.removeEventListener('resize', this.throttledFunction);

        let body = document.getElementsByTagName("BODY")[0];
        body.style.overflowY = 'auto';
    }

    notInViewPort() {
        try {
            const compareBoundingDiv = document.getElementById('compareBoundingDiv');
            const menuNavigationContainer = document.getElementById('menuNavigationContainer');
            const productCol = document.getElementsByClassName('productCol')[0];
            let isEnoughSpaceForFullscreen = true;
            if (productCol) {
                isEnoughSpaceForFullscreen = window.innerHeight + this.state.fullscreenOffset < productCol.offsetHeight;
            }
            let positionOfElementAboveMenuNavigationContainer = compareBoundingDiv.getBoundingClientRect().top;
            let positionOfMenuNavigationContainerInViewport = menuNavigationContainer.getBoundingClientRect().top;

            let notInViewPort = positionOfMenuNavigationContainerInViewport < 0 || positionOfElementAboveMenuNavigationContainer < 0;

            if (isEnoughSpaceForFullscreen) {
                if (notInViewPort) {
                    this.setState({ ...this.state, fullscreenOffset: 0 });
                    sendMessageToParentSite('if');
                } else {
                    this.setState({ ...this.state, fullscreenOffset: 200 });
                    sendMessageToParentSite('else');
                }
                return notInViewPort;
            }


        } catch (e) {

        }
        return false;
    }

    componentScrollEvents = _.debounce(() => {
        const categoryContainer = document.getElementById(`categoriesMainContainer`);
        let currentElement = document.getElementsByClassName('is-current');

        if (currentElement && currentElement.length > 0) {
            const currentCategoryId = (currentElement[0] && currentElement[0].childNodes[0]) ? currentElement[0].childNodes[0].id : -1;
            const currentCategory = document.getElementById(currentCategoryId);

            if (currentCategory) {
                const currentCategoryOffsetLeft = currentCategory.offsetLeft;
                categoryContainer.scrollTo(currentCategoryOffsetLeft, 0);
            }
        }

        this.notInViewPort();
    }, 100);


    iosTouchEvents() {
        const compareBoundingDiv = document.getElementById('compareBoundingDiv');
        const menuNavigationContainer = document.getElementById('menuNavigationContainer');

        this.categoryNameUnderline();

        let notInViewPort = this.notInViewPort();

        if (!this.props.isSearchProductOnFocus) {
            if (notInViewPort) {
                menuNavigationContainer.classList.add('stickyProductBarTop');
            } else {
                menuNavigationContainer.classList.remove('stickyProductBarTop');
                menuNavigationContainer.style.position = 'relative';
            }
        }

        let currentBounding;
        let compareBounding;
        setTimeout(() => {
            currentBounding = compareBoundingDiv.getBoundingClientRect().top;
        }, 100);
        setTimeout(() => {
            compareBounding = compareBoundingDiv.getBoundingClientRect().top;
            if (compareBounding && (currentBounding !== compareBounding)) {
                this.iosTouchEvents();
            }
        }, 300);

    }

    categoryNameUnderline() {
        const listGroups = document.getElementsByClassName('list-group');

        const categoryNamesObject = document.getElementsByClassName('productCategoryName');
        let objectToUnderline = categoryNamesObject[0];
        let indexOfCurrentElement = 0;
        for (let i = 0; i < categoryNamesObject.length; i++) {
            let currentObject = categoryNamesObject[i];
            let currentObjectBounty = currentObject.getBoundingClientRect().top;

            for (let x = 0; x < categoryNamesObject.length; x++) {
                let compareObject = categoryNamesObject[x];
                let compareObjectBounty = compareObject.getBoundingClientRect().top;

                if (currentObjectBounty < compareObjectBounty && compareObjectBounty < (this.props.menus.length > 1 ? 140 : 80)) {
                    objectToUnderline = compareObject;
                    indexOfCurrentElement = x;
                }
            }
        }

        for (let n = 0; n < listGroups.length; n++) {
            listGroups[n].classList.remove('is-current');
        }
        if (listGroups.length >= (indexOfCurrentElement + 1)) {
            listGroups[indexOfCurrentElement].classList.add('is-current');
            if (this.state.lastIndexOfCurrentElement !== indexOfCurrentElement) {

                listGroups[indexOfCurrentElement].scrollIntoView({ block: "nearest", inline: "center" });

            }
            this.setState(
                {
                    ...this.state,
                    lastIndexOfCurrentElement: indexOfCurrentElement
                });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.customerThemes.selectedTheme.type !== prevProps.customerThemes.selectedTheme.type
            && window.location.href.includes('previewMode')) {
            this.applyTheme();
        }

        if (this.props.categories !== undefined && this.props.categories !== null && this.props.categories.length > 0
            && this.props.products !== undefined && this.props.products !== null && this.props.products.length > 0
            && !this.state.stateAlreadyCopiedFromProps && this.props.menus !== undefined && this.props.menus !== null && this.props.menus[0] !== undefined && this.props.menus[0].id !== undefined) {


            this.setState({
                ...this.state,
                selectedMenuId: this.props.menus[0].id,
                selectedCategories: this.getSelectedCategories(this.props.menus[0].id),
                stateAlreadyCopiedFromProps: true
            });
        }

        const isAnyWizardOpen = this.state.selectedProductWizardOpened || this.state.generalProductWizardOpened || this.state.isProductWizardOpen;
        if (this.state.iOSDetected && this.state.isLoadedFromIntegration && isAnyWizardOpen) {
            const appWrapper = document.getElementById('appWrapper');
            const selectedProductModal = document.getElementsByClassName('modal')[0];

            if (selectedProductModal) {
                if (appWrapper) {
                    selectedProductModal.ontouchstart = (() => {
                        appWrapper.style.overflow = 'hidden';
                    });

                    selectedProductModal.ontouchend = (() => {
                        appWrapper.style.overflow = 'auto';
                    });
                }
            }
        }

        if (prevProps.menuDescription.generalNote !== this.props.menuDescription.generalNote) {

            if (document.getElementById('generalNote')) {
                document.getElementById('generalNote').innerHTML = this.props.menuDescription.generalNote;
            }

        }

        let currentTotalItemsInCart = (this.props.shoppingCart.orderProducts !== null && this.props.shoppingCart.orderProducts !== undefined && this.props.shoppingCart.orderProducts.length > 0) ? totalItemsCount(this.props.shoppingCart) : null;
        let prevTotalItemsInCart = (prevProps.shoppingCart.orderProducts !== null && prevProps.shoppingCart.orderProducts !== undefined && prevProps.shoppingCart.orderProducts.length > 0) ? totalItemsCount(prevProps.shoppingCart) : null;

        if (currentTotalItemsInCart > prevTotalItemsInCart) {
            let cartItemsIcon = document.getElementById("cartItemsIcon");
            let cartItemsNumber = document.getElementById("cartItemsNumber");


            if (cartItemsIcon !== null && cartItemsIcon !== undefined) {

                if (cartItemsIcon.classList.value.includes("cartItemsIconAnimation") && cartItemsNumber.classList.value.includes("cartItemsNumberAnimation")) {
                    cartItemsIcon.classList.remove("cartItemsIconAnimation");
                    cartItemsNumber.classList.remove("cartItemsNumberAnimation");
                    cartItemsIcon.classList.add("enlargeAnimation");
                    cartItemsNumber.classList.add("flipAnimation")
                } else if (cartItemsIcon.classList.value.includes("enlargeAnimation") && cartItemsNumber.classList.value.includes("flipAnimation")) {
                    cartItemsIcon.classList.remove("enlargeAnimation");
                    cartItemsNumber.classList.remove("flipAnimation");
                    cartItemsIcon.classList.add("cartItemsIconAnimation");
                    cartItemsNumber.classList.add("cartItemsNumberAnimation");
                } else {
                    cartItemsIcon.classList.add("cartItemsIconAnimation");
                    cartItemsNumber.classList.add("cartItemsNumberAnimation");
                }
            }
        }

    }

    componentWillMount() {
        if (this.props.menus == undefined || this.props.menus == null || this.props.menus.length == 0) {
            this.props.getRestaurantAvailableMenus();
        }

        this.copyStateFromProps();
    }

    handleScroll(isSelectCategoryMobile, category, i) {
        const currentElement = document.getElementsByClassName('is-current');

        if (currentElement && currentElement.length > 0) {
            if (isSelectCategoryMobile == true) {
                const categoryName = category.name;
                const element = document.getElementById(categoryName);

                if (element) {
                    const multipleMenus = this.props.menus.length > 1;
                    const headerOffset = multipleMenus ? 120 : 60;     // this is bcs of sticky navigation bar
                    const elementPosition = element.getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                    this.doScroll(element, offsetPosition);
                }
            } else {
                const categoryName = category.name;
                const element = document.getElementById(categoryName);

                // Handle the scrolling in case when the desktop is not scaled/scaled
                if (element && devicePixelRatio === 1) {

                    const headerOffset = 60;     // this is bcs of sticky navigation bar
                    const elementPosition = element.getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                    this.doScroll(element, offsetPosition);

                } else if (devicePixelRatio > 1) {

                    const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset;

                    let yOffset = -60;

                    switch (devicePixelRatio) {
                        case (1.25):
                            yOffset += 10;
                            this.doScroll(element, yCoordinate + yOffset);
                            break;
                        case 1.50:
                            yOffset += 10;
                            this.doScroll(element, yCoordinate + yOffset);
                            break;
                        default:
                            this.doScroll(element, yCoordinate + yOffset);
                    }
                }
            }
        } else {
            const newElement = document.getElementById(category.name);

            if (newElement) {
                newElement.scrollIntoView({ block: "start", behavior: "smooth" });
            }
        }

        if (this.state.iOSDetected && this.state.isLoadedFromIntegration) {
            const menuNavigationContainer = document.getElementById('menuNavigationContainer');
            menuNavigationContainer.classList.add('stickyProductBarTop');

            let that = this;
            setTimeout(function () {
                that.categoryNameUnderline();
            }, 500);
        }
    }

    // mobile ios in iframe need different scroll method and offset hacks
    doScroll(element, offsetPosition) {

        if (this.state.iOSDetected && this.state.isLoadedFromIntegration && isMobile) {
            element.style.paddingTop = '80px';
            element.style.marginTop = '-80px';
            element.scrollIntoView();
        } else {
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }

    }

    toggleProductWizard() {
        this.setState({
            ...this.state,
            isProductWizardOpen: !this.state.isProductWizardOpen
        });
    }

    toggleCartModal() {

        this.setState({
            ...this.state,
            isCartModalOpen: !this.state.isCartModalOpen
        }, () => {
            if (this.state.isCartModalOpen) {
                let body = document.getElementsByTagName("BODY")[0];
                body.style.overflow = 'hidden';
            } else if (!this.props.isPromotionsModalOpen) {
                let body = document.getElementsByTagName("BODY")[0];
                body.style.overflowY = 'auto';
            }
        });

        let shoppingCartDesktopWrapper = document.getElementById("shoppingCartDesktopWrapper");
        const isArabic = this.props.language === ARABIC;

        if (isArabic) {
            if (shoppingCartDesktopWrapper !== null && shoppingCartDesktopWrapper.classList.value.includes("shoppingCartInactiveRTL")) {
                shoppingCartDesktopWrapper.classList.remove("shoppingCartInactiveRTL");
                shoppingCartDesktopWrapper.classList.add("shoppingCartActiveRTL");
            } else if (shoppingCartDesktopWrapper !== null && shoppingCartDesktopWrapper.classList.value.includes("shoppingCartActive")) {
                shoppingCartDesktopWrapper.classList.remove("shoppingCartActiveRTL");
                shoppingCartDesktopWrapper.classList.add("shoppingCartInactiveRTL");
            } else {
                shoppingCartDesktopWrapper.classList.add("shoppingCartActiveRTL");
            }
        } else {
            if (shoppingCartDesktopWrapper !== null && shoppingCartDesktopWrapper.classList.value.includes("shoppingCartInactive")) {
                shoppingCartDesktopWrapper.classList.remove("shoppingCartInactive");
                shoppingCartDesktopWrapper.classList.add("shoppingCartActive");
            } else if (shoppingCartDesktopWrapper !== null && shoppingCartDesktopWrapper.classList.value.includes("shoppingCartActive")) {
                shoppingCartDesktopWrapper.classList.remove("shoppingCartActive");
                shoppingCartDesktopWrapper.classList.add("shoppingCartInactive");
            } else {
                shoppingCartDesktopWrapper.classList.add("shoppingCartActive");
            }
        }
    }

    selectProduct(product) {
        const filteredProduct = {
            id: product.id,
            name: product.name,
            price: product.price,
            category: getProductCategory(product.id, this.props.products, this.props.categories)
        }
        pushDataToGoogleTagManager('event', 'view-item', this.props.pwaAppRunning, filteredProduct);

        const isMenuAvailable = checkIfMenuIsAvailable(this.props.menus, product.menuId);
        const isCategoryAvailable = checkIfCategoryIsAvailable(this.props.categories, this.props.products, product);
        const isProductAvailable = checkIfProductIsAvailable(this.props.products, product);
        const isAvailable = isMenuAvailable && isCategoryAvailable && isProductAvailable;

        this.setState({ ...this.state, selectedProductId: product.id });
        this.props.getSelectedProduct(product.id, () => {
            this.setState({
                ...this.state,
                isProductWizardOpen: !this.state.isProductWizardOpen
            });
        }, null, null, isAvailable);
    }

    // autoAddToCart(itemToAdd) {
    //     const { selectedRestaurant, products, categories } = this.props;
    //     let currency = selectedRestaurant.currency;
        
    //     itemToAdd.instruction = null;

    //     if (!itemToAdd.quantity) {
    //         itemToAdd.quantity = 1;
    //     }

    //     // Google Analytics
    //     let filteredProduct = {
    //         id: itemToAdd.id,
    //         currency: currency,
    //         name: itemToAdd.name,
    //         price: calculateProductPrice(selectedRestaurant.allowToppingSubstitution, itemToAdd.price, itemToAdd.quantity, itemToAdd),
    //         quantity: itemToAdd.quantity,
    //         category: getProductCategory(itemToAdd.id, products, categories)
    //     };

    //     pushDataToGoogleTagManager('event', 'addToCart', this.props.pwaAppRunning, filteredProduct);

    //     this.props.addProductToCart(itemToAdd);
    // }

    copyStateFromProps() {
        if (this.props.menus !== undefined && this.props.menus !== null && this.props.menus.length > 0
            && this.props.categories !== undefined && this.props.categories !== null && this.props.categories.length > 0
            && !this.state.stateAlreadyCopiedFromProps) {

            this.setState({
                ...this.state,
                currency: this.props.selectedRestaurant.currency,
                selectedMenuId: this.props.menus[0].id,
                selectedCategories: this.getSelectedCategories(this.props.menus[0].id),
                stateAlreadyCopiedFromProps: true
            }, () => this.props.selectRestaurantMenu(this.props.menus[0].id));
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    toggleModal() {
        const path = '/menu-screen';
        this.props.closeStartOrderScreen(path);
    }


    changeState({ target }) {
        this.setState({
            ...this.state,
            [target.name]: target.value,
            selectedCategories: this.getSelectedCategories(target.value)
        }, () => this.props.selectRestaurantMenu(target.value));

        if (this.state.iOSDetected) {
            let element = document.getElementsByClassName('productCategoryName')[0];
            element.style.paddingTop = '80px';
            element.style.marginTop = '-80px';
            element.scrollIntoView();
        } else {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 600);
        }

        setTimeout(() => { this.setState({ loadScrollspyElements: true }) }, 100);
    }

    getSelectedCategories(selectedMenuId) {
        const { categories } = this.props;

        let selectedCategories = [];

        for (let i = 0; i < categories.length; i++) {
            if (categories[i].menuId == selectedMenuId) {
                selectedCategories.push(categories[i]);
            }
        }

        return selectedCategories;
    }

    editProductFromCart(product, index) {
        const filteredProduct = {
            id: product.id,
            name: product.name,
            price: product.price,
            category: getProductCategory(product.id, this.props.products, this.props.categories)
        };
        pushDataToGoogleTagManager('event', 'view-item', this.props.pwaAppRunning, filteredProduct);

        if (product.promotionFreeProduct) {
            this.props.getSelectedProduct(product.id, (productUpdated) => {
                this.props.editProduct(productUpdated, index, () => this.setState({ ...this.state, isProductWizardOpen: !this.state.isProductWizardOpen }), product)
            }, product.quantity);
        } else {
            this.props.editProduct(product, index, () => this.setState({ ...this.state, isProductWizardOpen: !this.state.isProductWizardOpen }));
        }
    }

    renderShoppingCart() {
        const checkoutButton = i18n.t('screens:shoppingCart.checkOutNow');
        const isShoppingCartSticky = (window.innerWidth > 865) ? true : false;
        const isLandscapeView = (window.orientation == 90 || window.orientation == -90) ? true : false;

        const isMobileView = isMobile ? true : (isLandscapeView && window.outerHeight <= 575) ? true : false;

        const isMobileLandscapeView = (isLandscapeView) ? (window.outerHeight <= 575 && window.outerHeight > 0) ? true : false : true;
        const repositionedSignInButton = window.outerWidth >= 1040 && window.outerWidth <= 1455 ? 20 : 0;
        const { stickyProfileStyle } = styles;
        const isArabic = this.props.language === ARABIC;
        const enableLargeImageDesign = (this.props.selectedRestaurant ? this.props.selectedRestaurant.enableLargeImageDesign : false) || (this.props.estimateOrderTime ? this.props.estimateOrderTime.enableLargeImageDesign : false);

        if (this.props.menus !== undefined && this.props.menus !== null && this.props.menus.length > 0) {
            // if (isShoppingCartSticky == true || !isMobileLandscapeView) {
                return (
                    <Col style={{ position: 'absolute', right: 0, zIndex: '10' }}>
                        <div style={isArabic ? { display: 'flex', flexDirection: 'column', paddingLeft: '10px' } : { display: 'flex', flexDirection: 'column', paddingRight: '10px' }}>
                            <div className="stickyProfile" style={stickyProfileStyle}>
                                <CartItemsButton
                                    {...this.props}
                                    styles={styles}
                                    openLoginScreen={this.openLoginScreen.bind(this)}
                                    isArabic={isArabic}
                                    toggleCartModal={this.toggleCartModal.bind(this)}
                                />
                            </div>
                        </div>
                    </Col>
                );
            // } else {
            //     return (
            //         <ShoppingCart
            //             isCheckout={false}
            //             isCartStickedToBottom={isMobileView}
            //             orderButton={checkoutButton}
            //             history={this.props.history}
            //             editProductFromCart={this.editProductFromCart.bind(this)}
            //             selectProduct={this.selectProduct}
            //         />
            //     );
            // }
        }
    }

    openLoginScreen(signInPressed) {
        const path = '/menu-screen';

        if (this.props.menuPreviewMode == false || this.props.menuPreviewMode == 'false') {
            if (this.props.shoppingCart.orderProducts.length && this.props.shoppingCart.total > this.props.selectedRestaurant.minimumOrderAmount) {
                document.getElementById('shoppingCartButton').click();
            } else {
                this.props.openStartOrderScreen(path);
            }

            if (signInPressed) {
                this.props.changeSignInPressed(true);
            }
        }
    }

    renderModalWindow() {
        if (this.props.startOrder.currentPagePath == '/menu-screen/sms-confirmation') {
            return <SmsConfirmationScreen toggle={this.toggleModal} isCheckoutLogin={true} hideBackButton={true} />

        } else if (this.props.startOrder.currentPagePath == '/menu-screen/user-credentials') {
            return <UserCredentialsScreen toggle={this.toggleModal} isCheckoutLogin={true} history={this.props.history} />

       } else if (this.props.startOrder.currentPagePath == '/menu-screen/phone-number') {
          return <PhoneNumberScreen toggle={this.toggleModal} isOrderDone={true} isCheckoutLogin={true} />
        
        } else {
            return <LoginTypesScreen toggle={this.toggleModal} isOrderDone={true} hideBackButton={true} isCheckoutLogin={true} />
        }
    }

    redirectToMenuuHomepage = () => {
        let integrationDetected = false;
        if (new URLSearchParams(window.location.search).get('brandId')) {
            integrationDetected = true;
        }

        if (integrationDetected) {
            sendMessageToParentSite('redirect');
        } else {
            window.location.href = "https://menuu.com/?utm_source=menuu&utm_medium=link&utm_campaign=powered-by-menuu";
        }
    };

    renderProductByCategory() {
        const { menuContainer } = styles;
        const isArabic = this.props.language === ARABIC;
        const enableLargeImageDesign = (this.props.selectedRestaurant ? this.props.selectedRestaurant.enableLargeImageDesign : false) || (this.props.estimateOrderTime ? this.props.estimateOrderTime.enableLargeImageDesign : false);

        return (
            <div style={menuContainer} className="menuContainer" >
                <ProductsScreen
                    {...this.props}
                    styles={styles}
                    selectedCategories={this.state.selectedCategories}
                    selectProduct={this.selectProduct}
                    currency={this.state.currency}
                    hiddenStickyMenu={this.state.hiddenStickyMenu}
                    noSearchedProducts={this.noSearchedProducts.bind(this)}
                    isProductMatchingSearch={this.isProductMatchingSearch.bind(this)}
                    isArabic={isArabic}
                    sendDataToParent={this.sendDataToParent}
                    customerThemes={this.props.customerThemes}
                    products={this.props.products}
                    toggleSearchProductFocus={this.props.toggleSearchProductFocus}
                    enableLargeImageDesign={enableLargeImageDesign}
                />
                {/* {this.renderShoppingCart()} */}
            </div>
        );
    }

    renderProductByCategoryPwa() {
        const { menuuLogoColor } = this.props.customerThemes.selectedTheme;
        const enableLargeImageDesign = (this.props.selectedRestaurant ? this.props.selectedRestaurant.enableLargeImageDesign : false) || (this.props.estimateOrderTime ? this.props.estimateOrderTime.enableLargeImageDesign : false);
        const isArabic = this.props.language === ARABIC;

        return (
            <Fragment>
                <ProductsScreenPwa
                    menus={this.props.menus}
                    products={this.props.products}
                    shoppingCart={this.props.shoppingCart}
                    selectedCategories={this.state.selectedCategories}
                    currency={this.state.currency}
                    selectRestaurantMenu={this.props.selectRestaurantMenu}
                    selectProduct={this.selectProduct}
                    customerThemes={this.props.customerThemes}
                    numberOfMenus={this.props.menus.length}
                    styles={styles}
                    renderFirstCategoryHiddenId={renderFirstCategoryHiddenId}
                    isPWA={false}
                    generalNote={this.props.menuDescription.generalNote}
                    hiddenStickyMenu={this.state.hiddenStickyMenu}
                    noSearchedProducts={this.noSearchedProducts.bind(this)}
                    isProductMatchingSearch={this.isProductMatchingSearch.bind(this)}
                    enableLargeImageDesign={enableLargeImageDesign}
                    isArabic={isArabic}
                    sendDataToParent={this.sendDataToParent}
                    toggleSearchProductFocus={this.props.toggleSearchProductFocus}
                />
                <MenuuLogoSVG width={120} height={147} fill={menuuLogoColor} style={{ display: 'block', cursor: 'pointer', width: 120, margin: '0 auto', paddingBottom: 100 }} onClick={this.redirectToMenuuHomepage} />
                {/* {this.renderShoppingCart()} */}
            </Fragment>
        )
    }

    sendDataToParent(hiddenStickyMenu, matchingProducts) {

        this.setState({
            ...this.state,
            hiddenStickyMenu,
            matchingProducts
        });
    }

    isProductMatchingSearch(productId) {
        const productFound = this.state.matchingProducts.filter(product => product.id === productId);

        return productFound.length;
    }

    noSearchedProducts(category) {
        return this.state.matchingProducts.filter(product => product.categoryId === category.id).length === 0;
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { primaryColor, fontWeight, mainTextColor, primaryFontColor, menuItemsBackground, menuBackground, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        customizedStyles.productDescriptionStyle.color = mainTextColor;
        customizedStyles.productPriceStyle.color = primaryColor;
        customizedStyles.badge.background = primaryColor;
        customizedStyles.largeImageBadge.background = primaryColor;
        customizedStyles.productNameStyle.color = primaryFontColor;
        customizedStyles.productNameStyleMobile.color = primaryFontColor;
        customizedStyles.productPriceStyle.fontWeight = fontWeight;
        customizedStyles.categoryNameStyle.color = primaryFontColor;
        customizedStyles.categoryNameStyleRTL.color = primaryFontColor;
        customizedStyles.profileNameStyle.color = primaryFontColor;
        customizedStyles.profileNameStyleRTL.color = primaryFontColor;
        customizedStyles.generalNote.color = mainTextColor;
        customizedStyles.categoryDescriptionDesktop.color = mainTextColor;
        customizedStyles.categoryDescriptionDesktopRTL.color = mainTextColor;
        customizedStyles.categoryDescriptionMobile.color = mainTextColor;
        customizedStyles.menuStyle.backgroundColor = menuItemsBackground;
        customizedStyles.menuStyle.color = primaryFontColor;
        customizedStyles.generalNoteMobile.backgroundColor = menuBackground;
        customizedStyles.generalNoteMobile.color = mainTextColor;
        customizedStyles.generalNoteMobileMultipleMenus.backgroundColor = menuBackground;
        customizedStyles.generalNoteMobileMultipleMenus.color = mainTextColor;
        customizedStyles.listItemNoThumbnail.backgroundColor = menuItemsBackground;
        customizedStyles.cardBody.backgroundColor = menuItemsBackground;
        customizedStyles.categoryNameStyle.fontFamily = fontFamilyType;
        customizedStyles.categoryNameStyleRTL.fontFamily = fontFamilyType;
        customizedStyles.categoryNameStyle.fontWeight = fontBold;
        customizedStyles.categoryNameStyleRTL.fontWeight = fontBold;
        customizedStyles.categoryNameStyle.fontStyle = fontItalic;
        customizedStyles.categoryNameStyleRTL.fontStyle = fontItalic;
        customizedStyles.categoryNameStyle.textTransform = uppercase;
        customizedStyles.categoryNameStyleRTL.textTransform = uppercase;
        styles = customizedStyles;
        return styles;
    }

    render() {
        this.copyStateFromProps();
        const isMobileView = window.innerWidth <= 865;
        const isArabic = this.props.language === ARABIC;
        const checkoutButton = i18n.t('screens:shoppingCart.checkOutNow');
        const enableLargeImageDesign = (this.props.selectedRestaurant ? this.props.selectedRestaurant.enableLargeImageDesign : false) || (this.props.estimateOrderTime ? this.props.estimateOrderTime.enableLargeImageDesign : false);
        let shoppingCartDesktopWrapper = document.getElementById("shoppingCartDesktopWrapper");
        const shoppingCartWrapperStyle = isMobileView && shoppingCartDesktopWrapper && (shoppingCartDesktopWrapper.classList.value.includes("shoppingCartActive") || shoppingCartDesktopWrapper.classList.value.includes("shoppingCartActiveRTL")) ? { width: '100%', paddingBottom: !isIOS ? '12px' : '0' } : null;

        return (
            <React.Fragment>
                <StickyNavigationBar
                    menus={this.props.menus}
                    categories={this.props.categories}
                    hiddenStickyMenu={this.state.hiddenStickyMenu}
                    selectRestaurantMenu={this.props.selectRestaurantMenu}
                    changeState={this.changeState.bind(this)}
                    handleScroll={this.handleScroll}
                    selectedMenuId={this.state.selectedMenuId}
                    selectedCategories={this.state.selectedCategories}
                    loadScrollspyElements={this.state.loadScrollspyElements}
                    styles={styles}
                    customerThemes={this.props.customerThemes}
                    sendDataToParent={this.sendDataToParent}
                    setMatchingProductsInParent={this.setMatchingProducts}
                    isArabic={isArabic}
                    renderShoppingCart={this.renderShoppingCart.bind(this)}
                    enableLargeImageDesign={enableLargeImageDesign}
                />
                {isMobileView || isMobileOnly ? this.renderProductByCategoryPwa() : this.renderProductByCategory()}
                <MenuLoaderContainer {...this.props} />
                <MenuScreenAlerts {...this.props} />
                {
                    this.state.isProductWizardOpen && !this.props.loading.backgroundLoader && !this.props.loading.transparentLoader ?
                        <CommonModal isOpen={this.state.isProductWizardOpen} dialogName='products' toggle={this.toggleProductWizard} className='productWizard' isCartModalOpen={this.state.isCartModalOpen}>
                            <ProductWizard
                                toggle={this.toggleProductWizard}
                                selectedProduct={this.props.selectedProduct}
                                selectedRestaurant={this.props.selectedRestaurant}
                                selectedMenuId={this.state.selectedMenuId}
                                menus={this.props.menus}
                                categories={this.props.categories}
                                products={this.props.products}
                                selectedTheme={this.props.customerThemes.selectedTheme}
                                addProductToCart={this.props.addProductToCart}
                                currency={this.state.currency}
                                pwaAppRunning={this.props.pwaAppRunning}
                                isLoadedFromIntegration={this.state.isLoadedFromIntegration}
                                orderTypes={this.props.orderTypes.orderTypes}
                            />
                        </CommonModal> : null
                }
                {/* {
                    this.props.isShoppingCartModalOpen ?
                        <CommonModal isOpen={this.props.isShoppingCartModalOpen} toggle={this.props.toggleShoppingCartModal} dialogName='shoppingCartModal' className='shoppingCartModal'>
                            <ShoppingCartScreen
                                selectedTheme={this.props.customerThemes.selectedTheme}
                                history={this.props.history}
                                selectedMenuId={this.state.selectedMenuId}
                                isLoadedFromIntegration={this.state.isLoadedFromIntegration}
                            />
                        </CommonModal> : null
                } */}
                {
                    this.props.isPromotionsModalOpen ?
                        <CommonModal isOpen={this.props.isPromotionsModalOpen} toggle={this.props.togglePromotionsModal} dialogName='promotionsModal' className='promotionsModal'>
                            <PromotionsScreen
                                selectedTheme={this.props.customerThemes.selectedTheme}
                                history={this.props.history}
                            />
                        </CommonModal> : null
                }
                {
                    this.state.isOpen ?
                        <Modal className="modalStep startOrder" isOpen={this.state.isOpen} toggle={this.toggle} size='lg'>
                            {this.renderModalWindow()}
                        </Modal> : null
                }
                {
                    this.state.isCartModalOpen ?
                        <div onClick={() => this.toggleCartModal()} style={{ width: '100wh', height: '100vh', position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, zIndex: 15, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div> : null
                }
                <div id="shoppingCartDesktopWrapper" className={isArabic ? "shoppingCartDesktopWrapperRTL" : "shoppingCartDesktopWrapper"} style={shoppingCartWrapperStyle}>
                    <ShoppingCart
                        isCheckout={false}
                        orderButton={checkoutButton}
                        history={this.props.history}
                        editProductFromCart={this.editProductFromCart.bind(this)}
                        selectProduct={this.selectProduct}
                        serviceNote={this.props.menuDescription.serviceNote}
                        enableLargeImageDesign={enableLargeImageDesign}
                        toggleCartModal={this.toggleCartModal.bind(this)}
                        isCartModalOpen={this.state.isCartModalOpen}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {

    return {
        loading: state.loading,
        brandId: state.brand.id,
        customer: state.customer,
        selectedRestaurant: state.selectedRestaurant,
        menus: state.restaurantMenus.menus,
        menuPreviewMode: state.menuPreviewMode.previewMode,
        categories: state.restaurantCategories,
        products: state.restaurantProducts,
        shoppingCart: state.shoppingCart,
        selectedProduct: state.selectedProduct,
        errorMessage: state.errorMessage,
        alertMessage: state.alertMessage,
        customerThemes: state.customerThemes,
        startOrder: state.startOrder,
        pwaAppRunning: state.pwaAppRunning,
        loyalty: state.loyalty,
        orderTypes: state.orderTypes,
        paymentProvider: state.paymentProvider,
        menuDescription: state.menuDescription,
        firstStamp: state.storeLocalStorage.firstStamp,
        storeLocalStorage: state.storeLocalStorage,
        isSearchProductOnFocus: state.commonReducer.isSearchProductOnFocus,
        isPromotionsModalOpen: state.commonReducer.isPromotionsModalOpen,
        isShoppingCartModalOpen: state.commonReducer.isShoppingCartModalOpen,
        promotionPartners: state.promotionPartners,
        como: state.como,
        language: state.storeLocalStorage.language,
        customerSavedRestaurants: state.customerSavedRestaurants,
        estimateOrderTime: state.estimateOrderTime
    };
};

export default connect(mapStateToProps, {
    getRestaurantAvailableMenus,
    getSelectedProduct,
    editProduct, // New Wizard edit
    clearMessageAlert,
    selectRestaurantMenu,
    openStartOrderScreen,
    clearShoppingCartState,
    saveBrandIdToStore,
    loadCustomerSavedRestaurants,
    getEstimateOrderTime,
    getMissingPreviewData,
    getOldestActiveLoyalty,
    addProductToCart,
    getServiceMessages,
    clearServiceMessages,
    saveFirstStamp,
    changeAuthipayBackBtnFlag,
    changeSignInPressed,
    updateProfileBackBtnPath,
    togglePromotionsModal,
    toggleShoppingCartModal,
    getComoMemberDetails,
    clearCartAndPromoModals,
    voidPurchase,
    resetPromoCodeField,
    resetOldDiscountFlag,
    cancelComoPayment,
    toggleSearchProductFocus
})(MenuScreen);

let styles = {
    menuContainer: {
        maxWidth: 1600,
        margin: '0 auto',
        display: 'flex',
        padding: 0
    },
    menuStyle: {
        WebkitAppearance: 'menulist-button',
        MozAppearance: 'none'
    },
    card: {
        height: 125,
        margin: 0,
        marginBottom: 30,
        border: 'none',
        backgroundColor: 'transparent',
        borderRadius: 0,
    },
    largeImageCard: {
        // maxHeight: '400px',
        // maxWidth: '400px',
        margin: '0 auto 30px',
        border: 'none',
        borderRadius: 0,
        backgroundColor: 'transparent'
    },
    cardOutlined: {
        height: 125,
        marginTop: 20,
        border: 'none',
        backgroundColor: 'transparent',
        borderRadius: 0
    },
    largeImageCardOutlined: {
        width: '400px',
        maxHeight: '400px',
        border: 'none',
        background: 'transparent',
        borderRadius: 0,
        aspectRatio: '1 / 1'
    },
    cardBody: {
        padding: "12px 30px 12px 30px",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1
    },
    largeImageCardBody: {
        maxWidth: '400px',
        maxHeight: '400px',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: 0,
        margin: '0 10px'
    },
    productNameStyle: {
        marginBottom: 0,
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '1.125rem',
        padding: '0 5px 0 0'
    },
    productNameStyleMobile: {
        marginBottom: 0,
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '1.125rem',
        padding: '0 5px 0 0',
        maxWidth: '98%'
    },
    productDescriptionStyle: {
        fontSize: '0.875rem',
        padding: 0,
        color: '#6e7676',
        marginBottom: 0
    },
    productPriceStyle: {
        fontSize: '0.875rem',
        padding: '5px 5px 5px 0',
        color: '#62b400',
        marginLeft: 0,
        marginBottom: 0
    },
    categoryNameStyle: {
        fontSize: '30px',
        padding: '5px 0px 0px 16px',
        color: '#000',
        fontFamily: 'Roboto',
        fontWeight: 400,
    },
    categoryNameStyleRTL: {
        fontSize: '30px',
        padding: '5px 16px 0px 0px',
        color: '#000',
        fontFamily: 'Roboto',
        fontWeight: 400,
    },
    categoryDescriptionMobile: {
        fontSize: 15,
        color: "#676767",
        padding: "10px 16px",
        paddingBottom: 0,
        fontStyle: "italic",
        display: "block",
        width: "100%",
        position: "relative",
        top: "-7px",
        paddingLeft: 12
    },
    categoryDescriptionDesktop: {
        fontSize: 15,
        color: "#676767",
        padding: "10px 16px",
        paddingBottom: 0,
        fontStyle: "italic",
        display: "block",
        width: "100%",
        position: "relative",
        top: "-8px",
        paddingLeft: 14
    },
    categoryDescriptionDesktopRTL: {
        fontSize: 15,
        color: "#676767",
        padding: "10px 16px",
        paddingBottom: 0,
        fontStyle: "italic",
        display: "block",
        width: "100%",
        position: "relative",
        top: "-8px",
        paddingRight: 14
    },
    categoryNameStyleMobile: {
        fontSize: '30px',
        padding: '26px 12px 32px',
        marginBottom: 0,
        color: '#000',
        fontFamily: 'Roboto',
        fontWeight: 400
    },
    firstCategoryName: {
        padding: '26px 12px 32px',
        marginBottom: 0,
        marginTop: 0,
        color: '#000',
        fontFamily: 'Roboto',
        fontWeight: 400
    },
    menuBar: {
        padding: 0,
        height: 60,
        marginTop: -4,
        boxShadow: '1px 0px 24px 2px rgba(0,0,0,0.15)',
        backgroundColor: '#fff'
    },
    categoriesContainerMoreStyle: {
        float: 'left',
        height: 60,
        backgroundColor: '#fff'
    },
    categoriesContainerMoreStyleRTL: {
        float: 'right',
        height: 60,
        backgroundColor: '#fff'
    },
    badgeCol: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        top: 10,
        left: 18,
        zIndex: 1
    },
    badgeColRTL: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        top: 10,
        right: 18,
        zIndex: 1
    },
    largeImageBadgeCol: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        top: -13,
        left: 28,
        zIndex: 1
    },
    largeImageBadgeColRTL: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        top: -13,
        right: 28,
        zIndex: 1
    },
    badgeColMobile: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignSelf: 'center',
        paddingRight: 0,
        paddingLeft: 5
    },
    badgeColMobileLargeImage: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        padding: 0,
        top: -10,
        left: 10
    },
    badgeColMobileLargeImageRTL: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        padding: 0,
        top: -10,
        right: 10
    },
    badge: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 6,
        borderRadius: '50%',
        width: 26,
        height: 26,
        background: '#FA1106'
    },
    largeImageBadge: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 8,
        borderRadius: '50%',
        width: 30,
        height: 30,
        background: '#FA1106',
        fontSize: '14px'
    },
    listItemNoThumbnail: {
        borderLeftWidth: 0,
        borderRightWidth: 0,
        padding: 0
    },
    profileNameStyle: {
        marginLeft: -10,
        verticalAlign: 'middle',
        fontSize: '1.0625rem'
    },
    profileNameStyleRTL: {
        marginRight: -10,
        verticalAlign: 'middle',
        fontSize: '1.0625rem'
    },
    // shoppingCartIconStyle: {
    //     height: 'auto',
    //     position: 'relative',
    //     left: 12,
    //     top: 6
    // },
    // shoppingCartIconStyleRTL: {
    //     height: 'auto',
    //     position: 'relative',
    //     right: 12,
    //     top: 6,
    //     left: 0
    // },
    generalNote: {
        fontSize: 15,
        marginBottom: 10,
        zIndex: 2
    },
    generalNoteMobile: {
        fontSize: 15,
        padding: '0px 12px 0 12px',
        marginBottom: -40,
        zIndex: 2,
    },
    generalNoteMobileMultipleMenus: {
        fontSize: 15,
        padding: '0px 16px 0 12px',
        margin: '0px 0px -25px 0px',
        zIndex: 2,
    },
    profileNameLink: {
        padding: 0
    },
    stickyProfileStyle: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    }
};