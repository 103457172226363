import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import jwtDecode from "jwt-decode";
import { GOOGLE_CLIENT_ID, GENERIC_API_KEY } from "../../../utils/Constants";
import { saveGoogleUserData, newSocialLogin, submitLogin, startOrderScreenPath, activateLoader, stopLoader } from '../../../actions';
import { encryptData, hashKey } from '../../../utils/CommonUtils';

class GoogleSignInAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: null,
      userFirstName: '',
      userPicture: ''
    };

    this.googleIdentityServicesInit = this.googleIdentityServicesInit.bind(this);
    this.handleCallbackResponse = this.handleCallbackResponse.bind(this);
  }

  componentDidMount() {
    this.props.activateLoader();
    if (window.google && window.google.accounts) {
      this.googleIdentityServicesInit();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.newSocialLoginInfo !== prevProps.newSocialLoginInfo && this.props.newSocialLoginInfo) {
      if (this.state.userData && this.props.newSocialLoginInfo.providerName === 'google') {

        let newSocialLoginInfo = this.props.newSocialLoginInfo;
        newSocialLoginInfo.userFirstName = this.state.userFirstName;
        newSocialLoginInfo.userPicture = this.state.userPicture;

        this.props.newSocialLogin(newSocialLoginInfo);

        this.props.saveGoogleUserData(this.state.userData); 
        this.props.stopLoader();

        if (this.props.isCheckoutLogin) {
          const path = '/menu-screen/social-response';
          this.props.startOrderScreenPath(path);
        } else {
          this.props.history.push('/start-order/social-response');
        }
      }
    }
  }

  googleIdentityServicesInit() {
    window.google.accounts.id.initialize({
      client_id: GOOGLE_CLIENT_ID,
      ux_mode: "popup",
      callback: this.handleCallbackResponse,
    });

    window.google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      { type: "standard", theme: "filled_blue", size: "large", text: "continue_with", shape: "rectangular", width: "400" }
    );

    this.props.stopLoader();
  }

  handleCallbackResponse(response) {
    if (response.credential) {
      let userData = {};
      const userDecode = jwtDecode(response.credential);

      userData.jwtToken = response.credential;
      userData.socialProvider = "google";

      if (userDecode.sub) {
        userData.accountId = userDecode.sub;
      }        
      if (userDecode.name) {
        userData.name = userDecode.name;

        const fullName = userDecode.name;
        const fullNameSplit = fullName.split(' ');
        const firstName = fullNameSplit[0];

        this.setState({
          userFirstName: firstName
        });
      }
      if (userDecode.email) {
        userData.email = userDecode.email;
      }
      if (userDecode.picture) {
        userData.picture = userDecode.picture;

        this.setState({
          userPicture: userDecode.picture
        });
      }

      const year = new Date().getFullYear();
      const brandId = this.props.brand.id;
      const key = hashKey(`${GENERIC_API_KEY}${brandId}${year}`);

      const encrData = encryptData(userData, key);
      if (encrData) {
        this.setState({
            userData: encrData
        });
      }
      
      let isOrderDone = false;
      if (this.props.isOrderDone) {
        isOrderDone = true;      	
      }

      let isPWA = false;
      if (this.props.isPWA) {
        isPWA = true;
      }

      this.props.activateLoader();
      this.props.submitLogin(undefined, isOrderDone, isPWA, undefined, undefined, encrData, this.props.isCheckoutLogin);
    }
  }

  render() {
    return <div id="signInDiv"></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    newSocialLoginInfo: state.customer.newSocialLoginInfo,
    brand: state.brand
  };
};

export default withRouter(connect(mapStateToProps, { 
  saveGoogleUserData,
  newSocialLogin,
  submitLogin,
  startOrderScreenPath,
  activateLoader,
  stopLoader
})(GoogleSignInAuth));














