import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.css';
import './styles/bootstrap-overrides.css';
import { isAndroid } from 'mobile-device-detect';
import { FRONT_END_URL_DEV, FRONT_END_URL_QA } from './utils/Constants';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

let integrationDetected = false;
if (new URLSearchParams(window.location.search).get('brandId')) {
    integrationDetected = true;
}

//Conditionally importing iframe styles or noIframe styles after app.css to prevent style overriding
if (integrationDetected) {
    import('./styles/app.css').then(() => {
        import('./styles/iframeStyles.css').then(() => {});
    });
} else {
    import('./styles/app.css').then(() => {
        import('./styles/noIframeStyles.css').then(() => {});
    });
}

if (window.cordova) {
    document.addEventListener("deviceready", () => {
        if (isAndroid) {
            let permissions = window.cordova.plugins.permissions;
            permissions.requestPermission(permissions.POST_NOTIFICATIONS, success, error);
    
            function error() {
                console.log('Notifications permission is not turned on');
            }
    
            function success( status ) {
                console.log('Notifications permission is turned on');
                if( !status.hasPermission ) error();
            }
        }
        console.log('here is cordova');
        initApp();
    }, false);
} else {
    console.log('no cordova detected');
    initApp();
}

const baseURL = window.location.origin;
if (baseURL !== FRONT_END_URL_DEV && baseURL !== FRONT_END_URL_QA) {
    disableReactDevTools();	
}

function initApp () {
    ReactDOM.render(<App />, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister();