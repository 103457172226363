import axios from '../../../utils/AxiosUtils';
import _ from 'lodash';
import moment from "moment/moment";
import i18n from '../../../i18next';
import { push } from "connected-react-router";

import {
    LOADING_NO_BACKGROUND,
    CLEAR_TEMPORARY_ESTIMATE_ORDER_TIME_SUCCESS,
    ADD_OR_SUBSTRACT_QTY_TO_CART,
    GET_ESTIMATE_ORDER_TIME_SUCCESS,
    GET_TEMPORARY_ESTIMATE_ORDER_TIME_SUCCESS,
    OPEN_CHANGE_ORDER_TIME_SCREEN,
    SAVE_AVAILABLE_ORDERED_PRODUCTS,
    SAVE_ORDER_TIME_DETAILS,
    SAVE_START_ORDER_TIME,
    SHOW_SUCCESS_ALERT_MESSAGE,
    SHOW_ERROR_ALERT_MESSAGE,
    TOGGLE_UNAVAILABLE_PRODUCT_SCREEN,
    SAVE_COOK_TIME
} from '../../types';
import {
    transformProducts,
} from "../../../utils/CommonUtils";
import { transformShoppingCartProducts } from "../../../utils/DataTransformationUtils";
import { doCalculatePromotion, updateShoppingCartState } from "../ShoppingCartActions";
import { loadRestaurantAvailableMenus } from "../MenuActions";
import {
    errorProcessingPayment,
    saveFirstStamp,
    getPromotionPartner
} from '../../index';

export const getEstimateOrderTime = (restaurantId, selectedOrderType, deliveryZoneId, callback, dinePayTypeId) => {
    return (dispatch, getState) => {

        let queryParameters = `?orderTypeValue=${selectedOrderType}`;

        if (selectedOrderType === 'In Store') {
            queryParameters += `&dinePayTypeId=${dinePayTypeId}`;
        }

        if (selectedOrderType == 'Delivery') {
            queryParameters = `?orderTypeValue=${selectedOrderType}&deliveryZoneId=${deliveryZoneId}`;
        }

        const url = `/restaurant/${restaurantId}/estimate-order-time${queryParameters}`;

        axios(getState).get(url)
            .then(response => getEstimateOrderTimeSuccess(dispatch, response, callback))
            .catch(error => getEstimateOrderTimeError(error, callback));
    }
}

const getEstimateOrderTimeSuccess = (dispatch, response, callback) => {

    dispatch({ type: GET_ESTIMATE_ORDER_TIME_SUCCESS, payload: response.data });
    dispatch(getPromotionPartner(response.data.promotionPartner));

    if (callback) {
        // Causes the loading spinner to stop - timeout is set so that loader does not flicker
        setTimeout(callback, 900);
    }
}

const getEstimateOrderTimeError = (_, __, callback) => {

    if (callback) {
        // Causes the loading spinner to stop - timeout is set so that loader does not flicker
        setTimeout(callback, 900);
    }
};

export const getTemporaryEstimateOrderTime = (restaurantId, selectedOrderType, deliveryZoneId, dinePayTypeId) => {
    return (dispatch, getState) => {

        let queryParameters = `?orderTypeValue=${selectedOrderType}`;

        if (selectedOrderType === 'In Store') {
            queryParameters += `&dinePayTypeId=${dinePayTypeId}`;
        }

        if (selectedOrderType == 'Delivery') {
            queryParameters = `?orderTypeValue=${selectedOrderType}&deliveryZoneId=${deliveryZoneId}`;
        }

        const url = `/restaurant/${restaurantId}/estimate-order-time${queryParameters}`;

        axios(getState).get(url)
            .then(response => getTemporaryEstimateOrderTimeSuccess(dispatch, response));
    };
};

const getTemporaryEstimateOrderTimeSuccess = (dispatch, response) => {
    dispatch({ type: GET_TEMPORARY_ESTIMATE_ORDER_TIME_SUCCESS, payload: response.data });
};

export const saveOrderTimes = (data) => {
    return (dispatch) => {

        dispatch({ type: SAVE_ORDER_TIME_DETAILS, payload: data });
    }
};

export const changeOrderTimes = (data, restaurantId, isOrderHistory, isOrderNow, orderId, isMenuScreen, isAddToCart, callback, isCheckoutScreen) => {
    return (dispatch, getState) => {
        const { pwaAppRunning, selectedRestaurant } = getState();
        const currentDateAndTime = moment().add(selectedRestaurant.restaurantTimeOffset, 'milliseconds').format('DD-MM-YYYY HH:mm:ss');
        let wantedTime = (data.wantedTime) ? data.wantedTime : currentDateAndTime;
        if (data.timeSlotBased) {
            let fromTime = moment(`${data.time.fromHour}:${data.time.fromMinute}:00`, 'HH:mm:ss').format('HH:mm:ss');
            wantedTime = `${data.time.date} ${fromTime}`;
        }
        let products;
        if (isMenuScreen || isCheckoutScreen) {
            products = transformShoppingCartProducts(getState().shoppingCart.orderProducts, getState().selectedRestaurant.allowToppingSubstitution);
        } else {
            products = transformShoppingCartProducts(getState().temporaryOrderTime.allOrderedProducts, getState().selectedRestaurant.allowToppingSubstitution);
        }
        const transformedProducts = transformProducts(products);
        const selectedOrderTypeId = getState().orderTypes.selectedOrderType.id;
        const newOrderTime = {
            estimateOrderTime: data.estimateOrderTime,
            workingHoursByDate: data.workingHoursByDate,
            asap: data.asap,
            selectTime: data.selectTime,
            date: data.data,
            time: data.time,
            futureOrder: data.futureOrder,
            wantedTime: wantedTime,
            timeSlotBased: data.timeSlotBased,
            timeSlotsByDate: data.timeSlotsByDate,
            unAvailableOrderedProducts: _.uniqBy(transformedProducts, 'productId')
        };

        dispatch({ type: GET_TEMPORARY_ESTIMATE_ORDER_TIME_SUCCESS, payload: newOrderTime });

        const changeOrderTimeDTO = {
            futureOrder: data.futureOrder,
            wantedTime: wantedTime,
            orderId: (orderId) ? orderId : null,
            availableOrderedProducts: transformedProducts,
            orderTypeId: selectedOrderTypeId
        }

        const url = `/restaurant/${restaurantId}/order/change-time`;

        axios(getState).post(url, changeOrderTimeDTO)
            .then(response => {
                changeOrderTimesSuccess(dispatch, getState, response, isOrderHistory, isOrderNow, isMenuScreen, isAddToCart, callback, isCheckoutScreen);
                dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
            })
            .catch((error) => {
                console.log(error);
                dispatch({ type: SHOW_ERROR_ALERT_MESSAGE, payload: true });
                dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
                // if (!pwaAppRunning) {
                //     dispatch({ type: CLEAR_TEMPORARY_ESTIMATE_ORDER_TIME_SUCCESS });
                // }
            });
    }
}

const changeOrderTimesSuccess = (dispatch, getState, response, isOrderHistory, isOrderNow, isMenuScreen, isAddToCart, callback, isCheckoutScreen) => {
    let isPwaApp, brandId, combinedServiceProducts, responseDataOrderedProducts, copyOfResponseProducts, unavailableDealIDs, availableOrderedProducts, copyOfAvailableOrderedProducts, unAvailableOrderedProducts;
    isPwaApp = getState().pwaAppRunning;
    brandId = getState().brand.id;
    // wizard-like data
    combinedServiceProducts = getState().temporaryOrderTime.allOrderedProducts.slice(0);
    // server-like data
    responseDataOrderedProducts = response.data.availableOrderedProducts.slice(0);
    copyOfResponseProducts = responseDataOrderedProducts.slice(0); // only the available will remain
    unavailableDealIDs = []; // mealDealParentId-s are here
    availableOrderedProducts = [];
    unAvailableOrderedProducts = combinedServiceProducts.slice(0);

    responseDataOrderedProducts.map((orderedProduct, orderedProductIndex) => {
        // Simple Product
        if (!orderedProduct.mealDeal) {
            let orderedProductUNAVAILABLE = false;
            if (!orderedProduct.available) { // Whole product is unavailable
                orderedProductUNAVAILABLE = true;
            } else { // Product is available but one or more of its answers are not
                if (orderedProduct.availableOrderAnswers !== null) {
                    orderedProduct.availableOrderAnswers.map(orderAnswer => {
                        if (!orderAnswer.available) {
                            orderedProductUNAVAILABLE = true;
                        }
                    });
                }
            }

            // If product is unavailable replace it with null (to keep indices) in the response copy array - only the available remain
            if (orderedProductUNAVAILABLE) {
                copyOfResponseProducts.splice(orderedProductIndex, 1, null);
            }

            // if it is mealDeal (not one of its products i.e. !mealDealProduct) and is UNavailable remove it - only the available remain
        } else if (orderedProduct.mealDeal && !orderedProduct.mealDealProduct && !orderedProduct.available) {
            copyOfResponseProducts.splice(orderedProductIndex, 1, null);
            unavailableDealIDs.push(orderedProduct.productId);
            // product part of the DEAL 
        } else if (orderedProduct.mealDeal && orderedProduct.mealDealProduct) {
            let orderedProductUNAVAILABLE = false;
            let mealDealProductFound = false;
            unavailableDealIDs.map(mealDealParentId => {
                if (mealDealParentId === orderedProduct.productId) {
                    mealDealProductFound = true
                }
            });
            // remove product directly if it belongs to removed deal
            if (mealDealProductFound) {
                copyOfResponseProducts.splice(orderedProductIndex, 1, null);
            } else {
                if (!orderedProduct.available) { // Whole product is unavailable
                    orderedProductUNAVAILABLE = true;
                } else { // Product is available but one or more of its answers are not
                    if (orderedProduct.availableOrderAnswers !== null) {
                        orderedProduct.availableOrderAnswers.map(orderAnswer => {
                            if (!orderAnswer.available) {
                                orderedProductUNAVAILABLE = true;
                            }
                        });
                    }
                }

                // If product is unavailable => replace find its parent and remove it
                if (orderedProductUNAVAILABLE) {
                    // => remove the unavailable product
                    copyOfResponseProducts.splice(orderedProductIndex, 1, null);
                    // => remove its parent 
                    copyOfResponseProducts.map((product, productIndex) => {
                        if (product !== null) {
                            if (product.productId === orderedProduct.mealDealParentId || product.mealDealParentId === orderedProduct.mealDealParentId) {
                                copyOfResponseProducts.splice(productIndex, 1, null);
                            }
                        }
                    });
                }
            }
        }
    });

    // Remove null values from the array
    availableOrderedProducts = copyOfResponseProducts.filter(function (product) {
        if (product !== null) {
            return product;
        }
    });

    // This array copy is needed to avoid mutation
    copyOfAvailableOrderedProducts = availableOrderedProducts.slice(0);

    availableOrderedProducts.map((availableProduct, i) => {
        combinedServiceProducts.map(combinedProduct => {
            if ((availableProduct.productId === combinedProduct.id) && !copyOfAvailableOrderedProducts.includes(combinedProduct)) {
                copyOfAvailableOrderedProducts.splice(i, 1, combinedProduct);
            } else if (availableProduct.mealDealParentId) {
                if (availableProduct.mealDealParentId === combinedProduct.id) {
                    copyOfAvailableOrderedProducts.splice(i, 1, null);
                }
            }
        });
    });

    availableOrderedProducts = copyOfAvailableOrderedProducts.filter(function (product) {
        if (product) {
            return product;
        }
    });

    // Fill the unavailable products array
    combinedServiceProducts.map((product, i) => {
        availableOrderedProducts.map(availableProduct => {
            if (product.id === availableProduct.id) {
                combinedServiceProducts.splice(i, 1, null);
            }
        });
    });

    unAvailableOrderedProducts = combinedServiceProducts.filter(function (product) {
        if (product) {
            return product;
        }
    });
    //this is only for change time from menu screen
    if (isMenuScreen || isCheckoutScreen) {
        let copyOfOrderProducts = getState().shoppingCart.orderProducts.slice(0);
        response.data.availableOrderedProducts.forEach((availableProduct) => {
            copyOfOrderProducts.forEach((product) => {
                if (availableProduct.productId === product.id && !availableProduct.available) {
                    unAvailableOrderedProducts.push(product)
                }
            })
        });
    }
    const transformedResponse = {
        availableOrderedProducts: availableOrderedProducts,
        unAvailableOrderedProducts: unAvailableOrderedProducts
    };

    dispatch({ type: SAVE_AVAILABLE_ORDERED_PRODUCTS, payload: transformedResponse });

    if (transformedResponse.unAvailableOrderedProducts.length > 0) {

        if (isOrderHistory) {
            // dispatch(push(`/profile/order-history/order-time-confirm`));
            dispatch({ type: SAVE_ORDER_TIME_DETAILS, payload: getState().temporaryOrderTime });

            if (callback) {
                let allProductsUnavailable = false;
                const unAvailableOrderedProductsLength = transformedResponse.unAvailableOrderedProducts.length;

                if (unAvailableOrderedProductsLength === getState().temporaryOrderTime.availableOrderedProducts.length) {
                    allProductsUnavailable = true;
                }

                callback(allProductsUnavailable, unAvailableOrderedProductsLength);
            }
        } else if (isMenuScreen || isCheckoutScreen) {
            dispatch({ type: SAVE_ORDER_TIME_DETAILS, payload: getState().temporaryOrderTime });
            // dispatch(push(`/menu-screen/order-time-confirm`));
            // dispatch({ type: OPEN_CHANGE_ORDER_TIME_SCREEN, payload: '/menu-screen/order-time-confirm' });
            dispatch({ type: TOGGLE_UNAVAILABLE_PRODUCT_SCREEN });
        } else {
            dispatch(push(`/checkout/order-time-confirm`));
        }
    } else {

        const orderTimeData = {
            asap: getState().temporaryOrderTime.asap,
            date: getState().temporaryOrderTime.date,
            estimateOrderTime: getState().temporaryOrderTime.estimateOrderTime,
            futureOrder: getState().temporaryOrderTime.futureOrder,
            selectTime: getState().temporaryOrderTime.selectTime,
            taxIncludeInPrice: getState().estimateOrderTime.taxIncludeInPrice,
            time: getState().temporaryOrderTime.time,
            wantedTime: response.data.futureOrder ? getState().temporaryOrderTime.wantedTime : null,
            workingHoursByDate: getState().temporaryOrderTime.workingHoursByDate,
            timeSlotBased: getState().temporaryOrderTime.timeSlotBased,
            timeSlotsByDate: getState().temporaryOrderTime.timeSlotsByDate,
            cookTime: getState().estimateOrderTime.cookTime,
            additionalMoneyCookTimes: getState().estimateOrderTime.additionalMoneyCookTimes,
            showMenuuLogo: getState().estimateOrderTime.showMenuuLogo
        }

        dispatch({ type: SAVE_ORDER_TIME_DETAILS, payload: orderTimeData });
        loadRestaurantAvailableMenus(dispatch, getState);

        let copyOfOrderProducts = getState().shoppingCart.orderProducts.slice(0);

        if (isMenuScreen || isCheckoutScreen) {
            response.data.availableOrderedProducts.forEach((availableProduct) => {
                copyOfOrderProducts.forEach((product, i) => {
                    if (availableProduct.productId === product.id && !availableProduct.available) {
                        copyOfOrderProducts.splice(i, 1)
                    }
                })
            });
        } else {
            getState().temporaryOrderTime.availableOrderedProducts.map(availableProduct => {
                copyOfOrderProducts.push(availableProduct);
            });
        }

        const updatedProductsResult = updateShoppingCartState(getState, copyOfOrderProducts);
        // TIME STAMP
        const firstStamp = getState().storeLocalStorage.firstStamp;
        if (updatedProductsResult.orderProducts.length >= 1 && !getState().pwaAppRunning && firstStamp === 0) {
            // add stamp
            let firstStamp = Date.now();
            dispatch(saveFirstStamp(firstStamp));
        }

        dispatch({ type: ADD_OR_SUBSTRACT_QTY_TO_CART, payload: updatedProductsResult });
        // Clear TemporaryState
        // dispatch({ type: CLEAR_TEMPORARY_ESTIMATE_ORDER_TIME_SUCCESS });
        doCalculatePromotion(dispatch, getState);

        if (isOrderNow && !isPwaApp) {
            dispatch(push(`/checkout`));
            return;
        } else if (isOrderNow && isPwaApp) {
            dispatch(push(`/brand/${brandId}/order/checkout`));
            return;
        }

        if (isMenuScreen || isAddToCart) {
            if (isPwaApp) {
                dispatch(push(`/brand/${brandId}/order/menu`));
            } else {
                dispatch(push(`/menu-screen`));
            }

            if (isAddToCart) {
                dispatch({ type: SHOW_SUCCESS_ALERT_MESSAGE, payload: true });
            }

            if (callback) {
                callback();
            }
            return;
        }

        if (callback && !isOrderHistory) {
            callback();
        }
    };
};

export const openChangeOrderTimeScreen = (path) => {
    return (dispatch) => {
        dispatch({ type: OPEN_CHANGE_ORDER_TIME_SCREEN, payload: path });
        dispatch(push(path));
    };
};

export const clearTemporaryOrderTimeData = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_TEMPORARY_ESTIMATE_ORDER_TIME_SUCCESS });
    };
};

export const saveNewOrderTime = (isOrderNow, isMenuScreen, doNotCallMsg) => { // when u click yes
    return (dispatch, getState) => {

        loadRestaurantAvailableMenus(dispatch, getState);
        let productsToAdd, shoppingCart, orderProductsCopy;
        productsToAdd = getState().temporaryOrderTime.availableOrderedProducts.slice(0);
        shoppingCart = getState().shoppingCart;
        orderProductsCopy = getState().shoppingCart.orderProducts.slice(0);

        if (isMenuScreen) {
            getState().temporaryOrderTime.unAvailableOrderedProducts.forEach((unAvailableProduct) => {
                orderProductsCopy.forEach((product, i) => {
                    if (unAvailableProduct.id === product.id) {
                        orderProductsCopy.splice(i, 1)
                    }
                })
            })
        } else {
            productsToAdd.map(productToAdd => {
                orderProductsCopy.push(productToAdd);
            });
        }

        let updatedShopingCart = {
            orderProducts: orderProductsCopy,
            subtotal: shoppingCart.subtotal,
            deliveryFee: shoppingCart.deliveryFee,
            generalInstruction: shoppingCart.generalInstruction,
            tip: shoppingCart.tip,
            bonusTip: shoppingCart.bonusTip,
            total: shoppingCart.total,
            appliedPromotions: shoppingCart.appliedPromotions,
            freeDelivery: shoppingCart.freeDelivery,
            errorMessage: shoppingCart.errorMessage,
            vat: shoppingCart.vat,
            discount: shoppingCart.discount,
            promoCallsCount: shoppingCart.promoCallsCount
        }

        // Update shopping cart state before updating prices, promotions, etc..
        dispatch({ type: ADD_OR_SUBSTRACT_QTY_TO_CART, payload: updatedShopingCart });
        // Update prices, promotions, etc..
        const updatedProductsResult = updateShoppingCartState(getState, undefined);

        // TIME STAMP
        const firstStamp = getState().storeLocalStorage.firstStamp;
        if (updatedProductsResult.orderProducts.length >= 1 && !getState().pwaAppRunning && firstStamp === 0) {
            // add stamp
            let firstStamp = Date.now();
            dispatch(saveFirstStamp(firstStamp));
        }
        dispatch({ type: ADD_OR_SUBSTRACT_QTY_TO_CART, payload: updatedProductsResult });
        // Call applyPromotion service
        doCalculatePromotion(dispatch, getState);
        // Update OtderTimeReducer with TemporaryOrderTimeReducer data
        dispatch({ type: SAVE_ORDER_TIME_DETAILS, payload: getState().temporaryOrderTime });

        if (productsToAdd.length > 0 && !doNotCallMsg) {
            dispatch({ type: SHOW_SUCCESS_ALERT_MESSAGE, payload: true });
        }

        if (isOrderNow) {
            dispatch(push(`/checkout`));
        } else {
            dispatch(push(`/menu-screen`));
        }
    };
};

export const saveStartOrderTime = (time) => {
    return (dispatch) => {
        
        dispatch({ type: SAVE_START_ORDER_TIME, payload: time });
    };
};

export const availableTimeSlot = (placeOrderCallback) => {
    return (dispatch, getState) => {

        const { estimateOrderTime, selectedRestaurant, orderTypes } = getState();
        let wantedTime, fromTime;
        fromTime = moment(`${estimateOrderTime.time.fromHour}:${estimateOrderTime.time.fromMinute}:00`, 'HH:mm:ss').format('HH:mm:ss');
        wantedTime = `${estimateOrderTime.time.date} ${fromTime}`;

        const url = `/restaurant/${selectedRestaurant.restaurantId}/available-time-slot?wantedTime=${wantedTime}&orderType=${orderTypes.selectedOrderType.value}`;

        axios(getState).get(url)
            .then(response => isTimeSlotAvailable(response.data.flag, dispatch, placeOrderCallback))
            .catch(_ => console.log('error '))
    };
};

const isTimeSlotAvailable = (isAvailable, dispatch, placeOrderCallback) => {

    if (isAvailable) {
        placeOrderCallback();
    } else {
        dispatch(errorProcessingPayment({ message: i18n.t('screens:orderTimeScreen.timeSlotErr') }));
    }
};

export const saveLongestCookTime = (cookTime) => {
    return (dispatch) => {

        dispatch({type: SAVE_COOK_TIME, payload: cookTime})
    };
};
