import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../../i18next';
import { pushDataToGoogleTagManager, getProductCategory } from '../../../../utils/AnalyticsUtils';
import {
    getSelectedProduct,
    editProduct,
    getOldestActiveLoyalty
} from "../../../../actions/index";
import ShoppingCart from '../../../../components/order/shoppingCart/ShoppingCart';
import ApplicationStep from '../../common/ApplicationStep';

class ShoppingCartScreenPwa extends Component {
    constructor(props) {
        super(props);
        
        this.editProductFromCart = this.editProductFromCart.bind(this);
        this.selectProduct = this.selectProduct.bind(this);
    }

    goBack() {
        const brandId = this.props.brand.id;
        this.props.history.push(`/brand/${brandId}/order/menu`);
    }

    selectProduct(product) {

        const filteredProduct = {
            id: product.id,
            name: product.name,
            price: product.price,
            category: getProductCategory(product.id, this.props.restaurantProducts, this.props.restaurantCategories)
        };
        pushDataToGoogleTagManager('event', 'view-item', this.props.pwaAppRunning, filteredProduct);


        // Go to the route of the selected product
        this.props.history.push({ pathname: '/brand/' + this.props.brandId + '/order/product/' + product.id });
    }

    editProductFromCart(product, index) {
        const filteredProduct = {
            id: product.id,
            name: product.name,
            price: product.price,
            category: getProductCategory(product.id, this.props.restaurantProducts, this.props.restaurantCategories)
        };
        pushDataToGoogleTagManager('event', 'view-item', this.props.pwaAppRunning, filteredProduct);
        if (product.promotionFreeProduct) {
            this.props.getSelectedProduct(product.id, (productUpdated) => {
                this.props.editProduct(productUpdated, index, (productUpdated) => {
                    this.props.history.push({
                        pathname: '/brand/' + this.props.brand.id + '/order/product/' + product.id,
                        editProduct: {
                            product: productUpdated
                        }
                    });
                }, product)
            }, product.quantity);
        } else {
            this.props.editProduct(product, index, (productUpdated) => {
                this.props.history.push({
                    pathname: '/brand/' + this.props.brand.id + '/order/product/' + product.id,
                    editProduct: {
                        product: productUpdated
                    }
                })
            })
        }
    }

    renderShoppingCart() {
        const { menuBackground } = this.props.customerThemes.selectedTheme;
        return (
            <div style={{ height: '100%', backgroundColor: menuBackground }}>
                <ShoppingCart
                    isCheckout={false}
                    history={this.props.history}
                    editProductFromCart={this.editProductFromCart}
                    selectProduct={this.selectProduct}
                    serviceNote={this.props.menuDescription.serviceNote}
                />
            </div>
        )
    }

    render() {

        return (
            <Fragment>
                <ApplicationStep
                    hideBackButton={false}
                    transparentHeader={false}
                    goBack={this.goBack.bind(this)}
                    text={i18n.t('screens:shoppingCart.basket')}
                    content={this.renderShoppingCart()}
                    isLoadedFromCheckout={false}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        customer: state.customer,
        products: state.restaurantProducts,
        shoppingCart: state.shoppingCart,
        pwaAppRunning: state.pwaAppRunning,
        menuDescription: state.menuDescription,
        selectedRestaurant: state.selectedRestaurant,
        storeLocalStorage: state.storeLocalStorage,
        brand: state.brand,
        restaurantProducts: state.restaurantProducts,
        restaurantCategories: state.restaurantCategories,
        promotionPartners: state.promotionPartners,
        customerThemes: state.customerThemes
    };
};

export default connect(mapStateToProps, {
    getSelectedProduct,
    editProduct,
    getOldestActiveLoyalty
})(ShoppingCartScreenPwa);
