import { push } from 'connected-react-router';

import {
    CLOSE_START_ORDER_SCREEN,
    OPEN_START_ORDER_SCREEN,
    RUNNING_PWA,
    SET_CONNECTION_STATUS,
    SELECT_BRAND_ID_FAILED,
    CHANGE_SIGN_IN_PRESSED
} from '../../types';
import { PWA_APP } from '../../../utils/Constants';

export const openStartOrderScreen = (path) => {

    return (dispatch) => {

        dispatch({ type: OPEN_START_ORDER_SCREEN, payload: path });
        dispatch(push(path));
    }
};

export const closeStartOrderScreen = (path) => {

    return (dispatch, getState) => {

        const isSignInPressed = getState().storeLocalStorage.signInPressed;

        if (isSignInPressed) {
            dispatch({ type: CHANGE_SIGN_IN_PRESSED, payload: false })
        }

        dispatch({ type: CLOSE_START_ORDER_SCREEN, payload: path });
        dispatch({ type: SELECT_BRAND_ID_FAILED, payload: false });
        dispatch(push(path));
    }
};

export const startOrderScreenPath = (path) => {

    return (dispatch) => {

        dispatch({ type: OPEN_START_ORDER_SCREEN, payload: path });
    }
};


export const setApplicationType = (appType) => {

    let pwaRunning = false;
    (appType === PWA_APP) ? pwaRunning = true : pwaRunning = false;

    return (dispatch) => {
        dispatch({ type: RUNNING_PWA, payload: pwaRunning });
    }
};

export const setConnectionStatus = (status, history) => {
    return (dispatch, getState) => {

        const userOffline = !status;
        const brandId = getState().brand.id;

        // If user is offline go to MainScreenPwa
        if (userOffline) history.push('/brand/' + brandId + '/start-order/main-screen');

        dispatch({ type: SET_CONNECTION_STATUS, payload: status });
    }
};